
  import { Component } from 'vue-property-decorator'
  import Aggregation from './aggregation'

  import { Pipeline } from '@/entities/crm'
  import { CategoryDistributionOptions } from '@/components/aggregates/index'
  import { ProcessStatus } from '@/entities/settings'

type Category = Pipeline | ProcessStatus

@Component({
  components: {
    GSheet: () => import('../core/GSheet.vue'),
    ProgressCard: () => import('./ProgressCard.vue'),
  },
})
  export default class CategoryDistribution extends Aggregation {
  declare input: Array<Category>
  declare options: CategoryDistributionOptions

  total = null

  get aggregates () {
    const { input, options } = this
    if (!input) return []

    let mapper = ({ title, icon: { main: icon }, color, aggregates: { value } }: Category) =>
      ({ title, icon, color, value })

    if (options?.overwrite) {
      const { overwrite } = options
      mapper = ({ name, title, icon: { main: icon }, color, aggregates: { value } }: Category) =>
        ({ title: overwrite[name] || title, icon, color, value })
    }

    const aggregates = input.filter(_ => _.icon).map(mapper).filter(v => v)
    this.total = aggregates.reduce((total, { value }) => total + value, 0)
    return aggregates
  }

  get displayTotal () {
    const { options } = this
    return !options?.total?.hidden
  }
  }
